<template>
  <el-form
    ref="form"
    size="large"
    label-position="top"
    :model="formModel"
    :rules="formRules"
  >
    <el-form-item prop="selectedAccounIds" label="请先选择广告账户">
      <!-- 帐户选择 -->
      <account-sel
        ref="accountRef"
        style="margin-right: 10px"
        size="large"
        :collapseTags="true"
        :initData="accountIds"
        @change-account="changeAccount"
        @post-init="changeAccount"
      ></account-sel>
    </el-form-item>
    <el-form-item class="last-item">
      <!-- <el-button @click="handleCancel">取消</el-button> -->
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapState } from "vuex";
import accountSel from "@/views/adManagement/components/accountSel";
export default {
  components: {
    accountSel,
  },
  data() {
    return {
      formModel: {
        selectedAccounIds: [],
      },
      formRules: {
        selectedAccounIds: {
          required: true,
          message: "广告账户不能为空！",
          trigger: ["change"],
        },
      },
    };
  },
  computed: {
    ...mapState("batchAccount", ["accountIds"]),
  },
  methods: {
    changeAccount(val) {
      this.formModel.selectedAccounIds = val && val.length ? [...val] : [];
    },
    handleConfirm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await this.$store.dispatch("batchAccount/setAccountIds", {
            accountIds: [...this.formModel.selectedAccounIds],
          });
          this.$emit("close-account-sel-page");
        }
      });
    },
    handleCancel() {
      this.$refs.accountRef.selectedAccountIds = [...this.accountIds];
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  align-self: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
<style lang="scss">
.last-item {
  .el-form-item__content {
    text-align: right;
  }
}
</style>