<template>
  <el-cascader
    ref="accountCascader"
    placeholder="请选择广告账户"
    :size="size"
    :props="props"
    :options="options"
    filterable
    clearable
    :collapse-tags="collapseTags"
    v-model="selectedAccountIds"
    @change="accountChange"
    @visible-change="handleVisibleChange"
  ></el-cascader>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["size", "initData", "collapseTags"],
  data() {
    return {
      props: {
        value: "id",
        label: "name",
        children: "fbAdAccounts",
        multiple: true,
      },
      selectedAccountIds: [],
    };
  },
  computed: {
    ...mapState("batchAccount", ["accountsOptions"]),
    options: {
      get() {
        if (this.accountsOptions) {
          return [...this.accountsOptions];
        }
      },
      set() {
        this.$store.dispatch("batchAccount/setAccountsOptions", {
          accountsOptions: this.accountsOptions,
        });
      },
    },
  },
  methods: {
    accountChange(val) {
      this.$emit("change-account", val);
    },
    handleVisibleChange(visible) {
      if (!visible) {
        this.$emit("visible-change", this.selectedAccountIds);
      }
    },
  },
  watch: {
    initData: {
      handler(val) {
        this.selectedAccountIds = val;
        this.$emit("post-init", val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>