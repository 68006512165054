<template>
  <div class="draft-box-container">
    <div class="adManagement-container">
      <div
        class="table-top-bar"
        v-if="!ids.length"
      >
        <el-dropdown
          split-button
          type="primary"
          size="small"
          @command="handleCommand"
          @click="handleCommandClick"
        >
          {{ adType }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="创建标准广告">创建标准广告</el-dropdown-item>
            <!-- <el-dropdown-item command="创建商品广告">创建商品广告</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button type="primary" size="small" @click="createStandardAd">新建标准广告</el-button> -->
        <el-form
          :inline="true"
          size="small"
        >
          <el-form-item label="">
            <el-select
              v-model="formInline.level"
              placeholder="活动区域"
            >
              <el-option
                label="全部广告系列"
                value="campaign"
              ></el-option>
              <el-option
                label="全部广告组"
                value="adset"
              ></el-option>
              <el-option
                label="全部广告"
                value="ad"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-radio-group
              v-model="formInline.uploadStatus"
              size="small"
            >
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="DRAFT">草稿</el-radio-button>
              <el-radio-button label="UPLOADED">已上传</el-radio-button>
              <el-radio-button label="UPLOADING">上传中</el-radio-button>
              <el-radio-button label="UPLOAD_FAIL">上传失败</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button
              type="text"
              icon="el-icon-refresh"
              @click="refresh"
              :disabled="disabled"
              >刷新</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div
        class="toolsBar"
        v-else
      >
        <div class="toolsCon">
          <p>已选{{ ids.length }}项</p>
          <span>批量操作</span>
          <el-button
            type="primary"
            @click="uploadToFb"
            size="mini"
            >上传广告</el-button
          >
          <el-button
            type="primary"
            @click="editDrafBox('adset')"
            size="mini"
            :disabled="!canEdit('adset').length"
            >编辑广告组</el-button
          >
          <el-button
            type="primary"
            @click="editDrafBox('ad')"
            size="mini"
            :disabled="!canEdit('ad').length"
            >编辑广告</el-button
          >
          <el-button
            type="primary"
            @click="draftMove"
            size="mini"
            >草稿转移</el-button
          >
          <el-button
            type="primary"
            @click="copyCreateHandle"
            size="mini"
            >复制并创建</el-button
          >
        </div>
        <div class="close">
          <i
            class="el-icon-close"
            @click="clearIds"
          ></i>
        </div>
      </div>
    </div>
    <div>
      <el-table
        :data="draftList"
        ref="multipleTable"
        row-key="uniqueId"
        border
        @selection-change="handleSelectionChange"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :cell-class-name="checkbox"
        :height="720"
        v-loading="loading"
      >
        <el-table-column
          type="selection"
          v-if="formInline.level == 'campaign'"
          :selectable="canSelect"
        ></el-table-column>

        <el-table-column
          label="广告系列名称"
          width="320"
        >
          <template slot-scope="scope">
            <div class="nameWrap">
              <p>{{ scope.row.name }}</p>
              <div class="tools">
                <span>
                  <i class="el-icon-view"></i>
                  <el-button
                    size="mini"
                    type="primary"
                    @click="edit(scope.row.level, 'preview', scope.row.id, scope.row.account_id)"
                    >预览</el-button
                  >
                </span>
                <!-- <span v-if="scope.row.upload_status != 'UPLOADED'">
                    <i class="el-icon-edit"></i>
                    <el-button size="mini" type="primary" @click="edit(scope.row.level,'edit',scope.row.id)">编辑</el-button>
                  </span> -->
                <span v-if="scope.row.level == 'campaign' && scope.row.upload_status != 'UPLOADED'">
                  <i class="el-icon-delete"></i>
                  <el-button
                    size="mini"
                    type="primary"
                    @click="dele(scope.row.id)"
                    >删除</el-button
                  >
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="草稿id"
          prop="id"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间"
          width="360"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.create_time }}
              <span v-if="scope.row.upload_time">(上传于：{{ scope.row.upload_time }})</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="dateType"
          label="状态"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.upload_status == 'DRAFT'
                  ? '草稿'
                  : scope.row.upload_status == 'UPLOADED'
                  ? '已上传'
                  : scope.row.upload_status == 'UPLOADING'
                  ? '上传中'
                  : '上传失败'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="error_msg"
          label="描述"
          width="400"
        ></el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]"
          :page-size="10"
          :current-page.sync="page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- 编辑广告系列 -->
    <edit-campain
      :editCampainShow="editCampainShow"
      @close="close"
      :initData="initData"
      :id="campaignId"
      :accountId="rowAccountId"
      @getList="getList"
      :preview="disAbled"
    ></edit-campain>
    <!-- 编辑广告组 -->
    <edit-ad-group
      :editGroupShow="editGroupShow"
      @close="close"
      :initData="initData"
      :id="adSetId"
      :accountId="rowAccountId"
      :preview="disAbled"
      @getList="getList"
      type="DRAFT"
    ></edit-ad-group>
    <!-- 编辑广告 -->
    <edit-ads
      :editAdsShow="editAdsShow"
      @close="close"
      :initData="initData"
      :id="adId"
      :accountId="rowAccountId"
      :preview="disAbled"
      @getList="getList"
      type="DRAFT"
    ></edit-ads>
    <!-- 编辑草稿 -->
    <edit-draft
      :batchEditShow.sync="batchEditShow"
      :list="currentSelect"
      :initCreatAdData="initData"
      @get-list="getList"
      type="draft"
      :level="level"
    ></edit-draft>
    <!-- 草稿转移 -->
    <draft-move
      title="草稿转移"
      :selDraftIds="ids"
      :visible="draftMoveVisible"
      @close-dialog="draftMoveVisible = false"
      @close-dialog-refresh-list="closeDraftMoveRefreshList"
    ></draft-move>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import draftMove from './draftMove';
import EditAdGroup from '../createAd/components/editAdGroup.vue';
import editCampain from '../createAd/components/editAdSeries';
import editAds from '../createAd/components/editAd';
import editDraft from './editCopy/components/edit';
import { draftSearch, draftDelete, draftsUpload, adConstsMap, draftCopyAndUpload } from '@/api/creatAd.js';
export default {
  props: {
    multiAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      draftMoveVisible: false,
      msg: '草稿箱',
      formInline: {
        level: 'campaign',
        uploadStatus: '',
      },
      uploadShow: false,
      ids: [],
      draftList: [],
      editCampainShow: false,
      editGroupShow: false,
      editAdsShow: false,
      page: 1,
      pageSize: 10,
      total: 0,
      accountId: this.$store.getters.accountId,
      initData: this.$store.getters.initData,
      disAbled: false,
      campaignId: 0,
      adSetId: 0,
      adId: 0,
      adType: '创建标准广告',
      disabled: false,
      loading: false,
      batchEditShow: false,
      currentSelect: [],
      level: 'adset',
      rowAccountId: null,
    };
  },
  components: {
    draftMove,
    editCampain,
    EditAdGroup,
    editAds,
    editDraft,
  },
  computed: {
    ...mapState('num', ['selectedNum', 'multiAccountList']),
    ...mapState('initData', ['adAccountLabelList']),
    accounts() {
      if (this.multiAccount) {
        let list = [];
        if (this.multiAccountList.length) {
          list = this.multiAccountList;
        } else {
          let activeLabel = localStorage.getItem('multiAccountLabel') || this.adAccountLabelList[0].id;
          let index =
            this.adAccountLabelList.findIndex((v) => v.id == activeLabel) != -1
              ? this.adAccountLabelList.findIndex((v) => v.id == activeLabel)
              : 0;
          list = this.adAccountLabelList[index]?.list;
        }
        let list1 = list && list.length ? Array.from(new Set(list.map((v) => v.id))) : [this.accountId];
        let arr = [];
        list1.map((v) => {
          if (arr.every((k) => k != v)) {
            arr.push(v);
          }
        });
        return arr.length ? arr.join(',') : this.accountId;
      } else {
        return this.accountId;
      }
    },
  },
  watch: {
    // 监听广告账户id
    '$store.getters.accountId': function (v, ov) {
      // console.log(v)
      this.accountId = v;
      // console.log(ov)
    },
    '$store.getters.fbAccountId': function (v, ov) {
      this.fbAccountId = v;
    },
    accounts: {
      handler() {
        console.log('sssssssssssss');
        this.getList();
      },
      deep: true,
    },
    formInline: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    canSelect(row, index) {
      if (!this.currentSelect.length || this.currentSelect.map((v) => v.account_id).includes(row.account_id)) {
        return true;
      } else {
        return false;
      }
    },
    // 过滤没有上传成功的广告或者广告组，若返回数组为空，则当前层级不可编辑
    canEdit(level) {
      console.log(this.currentSelect);
      if (level == 'adset') {
        let list = [];
        this.currentSelect.forEach((v) => {
          list = list.concat(v.children.filter((m) => m.can_edit));
        });
        return list;
      }
      if (level == 'ad') {
        let list = [];
        this.currentSelect.forEach((v) => {
          v.children.forEach((m) => {
            list = list.concat(m.children.filter((k) => k.can_edit));
          });
        });
        console.log(list);
        return list;
      }
    },
    closeDraftMoveRefreshList() {
      this.draftMoveVisible = false;
      this.refresh();
    },
    draftMove() {
      this.draftMoveVisible = true;
    },
    // 复制并创建
    copyCreateHandle() {
      let ids = this.ids.join(',');
      this.$confirm('确定复制并创建选中的广告吗？', '提示')
        .then((res) => {
          this.$showLoading();
          draftCopyAndUpload({ draftCampaignIds: ids }).then((res) => {
            this.$hideLoading();
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '创建成功',
              });
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    // 关闭
    close(v) {
      this[v] = false;
    },
    // 点击创建广告类型
    handleCommandClick() {
      this.handleCommand(this.adType);
    },
    // 点击创建广告类型
    handleCommand(v) {
      // console.log(v);
      this.adType = v;
      if (v == '创建标准广告') {
        this.createStandardAd({ name: 'CreateAd' });
      } else {
        this.createStandardAd({ name: 'createFastAd' });
      }
    },
    // 点击编辑草稿
    editDrafBox(level) {
      this.level = level;
      this.batchEditShow = true;
    },
    handleSizeChange(val) {
      this.size = val;
      this.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    // 监听选择
    handleSelectionChange(v) {
      this.currentSelect = v;
      let ids = [];
      v.map((item) => {
        ids.push(item.id);
      });
      this.ids = ids;
    },
    // 获取广告创建常量(编辑的时候会用到)
    getInitData() {
      adConstsMap().then((res) => {
        // console.log(res);
        this.initData = res.data;
      });
    },
    refresh() {
      this.disabled = true;
      this.getList().then((res) => {
        this.$message({
          type: 'success',
          message: '刷新成功',
        });
        setTimeout(() => {
          this.disabled = false;
        }, 2000);
      });
    },
    getList() {
      this.loading = true;
      return draftSearch({ ...this.formInline, accountId: this.accounts, page: this.page, size: this.pageSize }).then(
        (res) => {
          // console.log(res);
          this.loading = false;
          this.draftList = res.data.content;
          this.total = res.data.totalElements;
          this.$emit('get-draft-num', res.data.totalElements);
          // this.clearIds();
        },
      );
    },
    checkbox(row) {
      if ((row.row.level === 'adset' || row.row.level === 'ad') && row.columnIndex === 0) {
        return 'mycell';
      }
    },
    // 清除已经选择的项
    clearIds() {
      this.ids = [];
      this.$refs.multipleTable.clearSelection();
    },
    // 点击新建标准广告
    createStandardAd(router) {
      this.$router.push(router);
    },
    // 点击预览或者编辑
    edit(adType, toolsType, id, rowAccountId) {
      // 预览

      if (toolsType == 'preview') {
        this.disAbled = true;
      } else {
        // 编辑
        this.disAbled = false;
      }
      // 广告系列
      // console.log(id);
      if (adType == 'campaign') {
        this.campaignId = id;
        this.editCampainShow = true;
      }
      if (adType == 'adset') {
        this.adSetId = id;
        this.editGroupShow = true;
      }
      if (adType == 'ad') {
        this.adId = id;
        this.editAdsShow = true;
      }
      this.rowAccountId = rowAccountId;
    },
    // 删除草稿系列
    dele(id) {
      // let ids = id?id:this.ids.join(',')
      let formData = new FormData();
      formData.append('draftCampaignId', id);
      this.$confirm('确定删除当前选中广告系列吗？', '删除提示')
        .then((res) => {
          this.$showLoading();
          draftDelete(formData).then((res) => {
            this.$hideLoading();
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '删除成功',
              });
              this.getList();
            }
          });
        })
        .catch((res) => {});
    },
    // 上传广告到fb
    uploadToFb() {
      let ids = this.ids.join(',');
      this.$confirm('确定将选中的广告系列上传到facebook吗？', '上传提示')
        .then((res) => {
          this.$showLoading();
          draftsUpload({ draftCampaignIds: ids }).then((res) => {
            this.$hideLoading();
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '上传成功',
              });
              this.getList();
            }
          });
        })
        .catch((res) => {});
    },
  },
  mounted() {
    this.getList();
    // this.getInitData();
  },
};
</script>
<style lang="scss">
.editAdsCon {
  position: relative;
}
.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 6666;
  background: rgba(255, 255, 255, 0.4);
  top: 0;
  right: 10px;
}
.mycell .el-checkbox__input {
  display: none;
}
.page {
  height: 50px;
  padding: 20px;
}
.el-drawer.rtl {
  outline: none;
  * {
    outline: none;
  }
}
.drawerCon {
  height: calc(100vh - 150px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
.demo-drawer__footer {
  text-align: center;
  padding: 15px;
  // background: #efefef;
  border-top: 1px solid #ddd;
}
</style>
<style lang="scss" scoped>
.draft-box-container {
  .el-table {
    margin-top: 10px;
    .nameWrap {
      width: 86%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 48px;
      &:hover {
        .tools {
          display: block;
          position: absolute;
          height: 48px;
          right: 0px;
          top: 12px;
          background: #f5f7fa;
          span {
            &:hover {
              .el-button {
                display: inline-block;
              }
              i {
                display: none;
              }
            }
          }
        }
      }
      .tools {
        display: none;
        span {
          display: inline-block;
          width: 50px;
          text-align: center;
          i {
            display: inline;
          }
          .el-button {
            display: none;
          }
        }
      }
    }
  }
  // padding: 0 16px;
  .toolsBar {
    margin: 10px 0;
    background: rgb(232, 241, 255);
    line-height: 32px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #409eff;
    font-size: 12px;
    .close {
      cursor: pointer;
    }
    .toolsCon {
      display: flex;
      align-items: center;
      p {
        background: #409eff;
        line-height: 28px;
        border-radius: 14px;
        padding: 0 10px;
        font-size: 12px;
        color: #fff;
        margin-right: 10px;
      }
      .el-button {
        margin-left: 15px;
      }
    }
  }
}
.table-top-bar {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 40px;
  .el-form {
    margin-left: 30px;
  }
  .el-form--inline .el-form-item {
    vertical-align: middle;
    margin-bottom: 0;
  }
}
</style>
