<template>
    <div>
        <div>
            <el-row>
                <el-col :span="8" v-if="showTag">
                    <time-tag @setDate="setDate" :adPickerOptions="pickerOptions"></time-tag>
                </el-col>
                <el-col :span="showTag?16:24">
                    <el-date-picker
                        :value="dateValue"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        @input="onDateValue"
                        format="yyyy/MM/dd"
                        value-format="yyyy-MM-dd"
                        @focus="drawWindowAttribute"
                        :size="size"
                        :clearable="false"
                        style="width:100%;"
                    >
                    </el-date-picker>
                </el-col>
            </el-row>
            <el-row ref="windowAttrEl" style="display:none;padding:10px;" :gutter="20">
                <el-col :span="4">
                    <span style="font-size:13px">归因时间窗</span>
                    <el-tooltip effect="dark" placement="bottom-start"
                                content="选择用户从点击或浏览广告到完成有价值操作（即转化）通常需要的时间。此操作不会影响正在投放的广告。">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </el-col>
                <el-col :span="6">
                    <el-select :value="viewWindowValue" @input="onViewWindowValue" size="mini">
                        <el-option
                                v-for="item in viewOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <el-select :value="clickWindowValue" @input="onClickWindowValue" size="mini">
                        <el-option
                                v-for="item in clickOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>

            </el-row>
        </div>
    </div>
</template>

<script>

    import {adPickerOptions, fnCSTToYmd} from "@/utils/utils";

    function AdDateRangeValue(actionAttributionWindows, dateRange) {
        this.actionAttributionWindows = actionAttributionWindows;//["1d_view","28d_click"]
        this.dateRange = dateRange;//
    }
    import timeTag from './timeTag.vue'
    export default {
        name: "AdDateRangePick",
        props: {
            value: {
                type: Object,
                required: false
            },
            size:String,
            hasLifeCyle:{
                type:Boolean,
                default:()=>true
            },
            hasTimeWindow:{
                type:Boolean,
                default:()=>true
            },
            showTag:{
                type:Boolean,
                default:()=>false
            }
        },
        components:{
            timeTag
        },
        computed: {
            defaultDateRange() {
                let end = new Date(),
                    start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * (24 * (30 - 1)));
                return [fnCSTToYmd(start, {tag: 1}), fnCSTToYmd(end, {tag: 1})];
            },
            clickWindowValue() {
                if (this.value && this.value.actionAttributionWindows) {
                    for (let i in this.value.actionAttributionWindows) {
                        let v = this.value.actionAttributionWindows[i];
                        if (this.clickOptions.some((s) => s.value === v)) return v;
                    }
                }
                return '';
            },
            viewWindowValue() {
                if (this.value && this.value.actionAttributionWindows) {
                    for (let i in this.value.actionAttributionWindows) {
                        let v = this.value.actionAttributionWindows[i];
                        if (this.viewOptions.some((s) => s.value === v)) return v;
                    }
                }
                return '';
            },
            dateValue:{
                get(){
                    if (this.value && this.value.dateRange) {
                        return this.value.dateRange;
                    }
                    return [];
                },
                set(){

                }
                
            },
            pickerOptions(){
                let options={
                    disabledDate:adPickerOptions.disabledDate,
                    shortcuts:adPickerOptions.shortcuts.filter(item=>!/生命周期/.test(item.text))
                };
                // console.log('this.$store.getters.currentAccountInfo,this.$store.getters.currentAccountInfo.createdTime',this.$store.getters.currentAccountInfo,this.$store.getters.currentAccountInfo.createdTime);
                if(this.hasLifeCyle){
                    if(this.$store.getters.currentAccountInfo){
                        let date=this.$store.getters.currentAccountInfo.createdTime;
                        if(date){
                            let startDate=new Date(date.substring(0,10));
                            options.shortcuts.push({
                                text: '生命周期',
                                onClick(picker) {
                                    if(picker){
                                        picker.$emit('pick', [startDate, new Date()]);
                                    }else{
                                        return [startDate, new Date()]
                                    }
                                    
                                }
                            });
                        }
                    }
                }
                options.shortcuts = options.shortcuts.filter(v=>v.quick).concat(options.shortcuts.filter(v=>!v.quick))
                return options;
            },
        },
        mounted() {
            this.initDefaultValue();
            // this.drawWindowAttribute();
        },
        methods: {
            initDefaultValue() {
                if (!this.dateValue || this.dateValue.length == 0) {
                    this.onDateValue(this.defaultDateRange)
                }
            },
            onInput() {
                let w = [];
                let dv = this.dateValueTmp;
                if (this.viewWindowValueTmp) w.push(this.viewWindowValueTmp);
                if (this.clickWindowValueTmp) w.push(this.clickWindowValueTmp);
                let v = new AdDateRangeValue(w, dv);
                console.log('abc,',v);
                this.$emit('input', v);
            },
            onDateValue(v) {
                this.dateValueTmp = v;
                this.onInput();
            },
            onViewWindowValue(v) {
                this.viewWindowValueTmp = v;
                this.onInput();
            },
            onClickWindowValue(v) {
                this.clickWindowValueTmp = v;
                this.onInput();
            },
            drawWindowAttribute(){
                if(this.hasTimeWindow){
                    let el=this.$refs.windowAttrEl.$el;
                    setTimeout(function () {
                        el.setAttribute('style',"padding:10px;padding-left:0")
                        document.getElementsByClassName('el-picker-panel__body-wrapper')[0].appendChild(el);
                    },100)
                }               
                // this.$el.appendChild(this.$refs.windowAttrEl);
            },
            setDate(v){
                this.$emit('input', v);
            }
        },
        data() {
            return {
                windowOptionsShow: false,
                dateValueTmp: this.dateValue || [],
                viewWindowValueTmp: this.viewWindowValue || '',
                clickWindowValueTmp: this.clickWindowValue || '',
                viewOptions: [{
                    value: '',
                    label: '无'
                }, {
                    value: '1d_view',
                    label: '1天浏览量'
                }, {
                    value: '7d_view',
                    label: '7天浏览量'
                }, {
                    value: '28d_view',
                    label: '28天浏览量'
                }],
                clickOptions: [{
                    value: '',
                    label: '无'
                }, {
                    value: '1d_click',
                    label: '1天点击量'
                }, {
                    value: '7d_click',
                    label: '7天点击量'
                }, {
                    value: '28d_click',
                    label: '28天点击量'
                }]
            }
        },

    }
</script>

<style scoped>

</style>