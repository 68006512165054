<template>
  <el-cascader
    ref="accountCascader"
    placeholder="请选择广告账户"
    :size="size"
    :props="props"
    :options="options"
    filterable
    v-model="selectedAccountIds"
    @change="accountChange"
  ></el-cascader>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import { adaccouts } from "@/api/adManagement";

export default {
  props: ["size", "initData"],
  data() {
    return {
      props: {
        value: "id",
        label: "name",
        multiple: true,
        lazy: true,
        lazyLoad: this.cusLazyLoad,
      },
      options: [],
      selectedAccountIds: [],
    };
  },
  computed: {
    ...mapState("batchAccount", ["accountIds"]),
    ...mapGetters("num", ["validNumLists"]),
    formatNums() {
      let arr = _.cloneDeep(this.validNumLists);
      arr.forEach(
        (item) =>
          (item.name = `${
            item.thirdUser.displayName
              ? item.thirdUser.displayName
              : item.thirdUser.thirdUserName
          }（ ${item.thirdUser.thirdUserEmail}）`)
      );
      return arr;
    },
  },
  methods: {
    addChild() {
      let arr = _.cloneDeep(this.selectedAccountIds);
      console.log("addChild-arr", arr);
      let flags = arr.map((item) => false);
      arr.forEach(async (item) => {
        let id = item[1];
        let index = this.options.findIndex((el) => el.id == id);
        if (index != -1) {
          let child = [];
          let res = await this.getAccounts(id);
          if (res.code == 200) {
            child = res.data.list;
            flags[index] = true;
            console.log("flags-1", flags);
          }
          this.$set(this.options[index], "children", child);
        }
      });
      console.log("flags", flags);
      if (flags.every((f) => f)) {
        console.log("this.options-last", this.options);
      }
    },
    getAccounts(id) {
      let params = { id, keyword: "", limit: 10000, page: 1 };
      return adaccouts(params).then((res) => {
        return res;
      });
    },
    async cusLazyLoad(node, resolve) {
      let arr = [],
        { level, data } = node,
        id = "";
      if (level == 1 && data) {
        id = data.id;
        let res = await this.getAccounts(id);
        console.log("res", res);
        if (res.code == 200) {
          arr = res.data.list;
        }
      }
      resolve(arr);
    },
    accountChange(val) {
      this.$emit("change-account", val);
    },
  },
  created() {
    this.options = [...this.formatNums];
  },
  watch: {
    initData: {
      handler(val) {
        if (val && val.length) {
          console.log("watch-initData", val);
          this.selectedAccountIds = val;
          this.addChild();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
</style>